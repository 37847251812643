import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiPhoneCall } from "react-icons/fi";
import { PiDotsNineThin } from "react-icons/pi";
import "../Styles/Header.css";
import "../Styles/topheader.css";
import { MdLocationSearching } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import Callback from "./Callback";
import { SlLocationPin } from "react-icons/sl";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Modal } from "react-bootstrap";

import axios from "axios";

const Header = () => {
  //To make form visible

  let [isFormVisible, setIsFormVisible] = useState(false);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //Frontend Integration

  //Get(Contact Details of Header)

  const [ContactDetails, setContactDetails] = useState(null);

  const getContactDetails = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactdetails/getcontactdetails"
      );
      if (res.status === 201) {
        setContactDetails(res.data.data1);
        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // console.log("ContactDetails: ", ContactDetails);
  //Get(Home Services)

  const [Department, setDepartment] = useState([]);

  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("Department", Department);

  useEffect(() => {
    getContactDetails();
    getHomeDepartment();
  }, []);

  //Post(Contact Enquiry Form)

  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Service, setService] = useState("");
  const [Message, setMessage] = useState("");
  const validatePhoneNumber = (number) => {
    const re = /^[6-9]\d{9}$/;
    return re.test(number);
  };
  // Post
  const postContactEnquiryForm = async () => {
    if (!Name) {
      alert("Name required!");
      return;
    }
    if (!Email) {
      alert("Email required!");
      return;
    }
    if (!Phone) {
      alert("Phone required!");
      return;
    }
    if (!validatePhoneNumber(Phone)) {
      return alert("Please enter a valid phone number (10 digits)");
    }
    if (!Service) {
      alert("Service required!");
      return;
    }
    if (!Message) {
      alert("Message required!");
      return;
    }
    try {
      const config = {
        url: "/createcontactenquiryform",
        method: "post",
        baseURL: "https://lehaim.in/api/contactenquiryform",
        headers: { "content-type": "application/json" },
        data: {
          name: Name,
          phone: Phone,
          email: Email,
          message: Message,
          service: Service,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        setName("");
        setPhone("");
        setEmail("");
        setService("");
        setMessage("");
        alert("We will call you shortly...");
        setIsFormVisible(false);
      }
    } catch (error) {
      // alert(error.response.data.error);
      console.log(error);
    }
  };

  return (
    <div>
      <header
        id="site-header"
        className={`header ${isFixed ? "fixed" : ""}`}
        style={{ backgroundColor: "#f1faff" }}
      >
        {ContactDetails?.map((item) => {
          return (
            <div className="container">
              <div className="containt">
                <div
                  className="cont-top-p"
                  style={{ backgroundColor: "#f1faff" }}
                >
                  <div className="top-h">
                    <div className="icons" style={{ color: "#65b145" }}>
                      <img
                        src={
                          "https://img.icons8.com/?size=100&id=47817&format=png&color=65b145"
                        }
                        alt=""
                        height={25}
                        width={25}
                      ></img>
                    </div>
                    <div className="open-p  ">Open 24/7</div>
                  </div>
                  <div className="top-h">
                    <div className="icons" style={{ color: "#65B145" }}>
                      <img
                        src={
                          " https://img.icons8.com/?size=100&id=3723&format=png&color=65B145"
                        }
                        alt=""
                        height={23}
                        width={25}
                      />
                    </div>
                    {item?.address?.slice(0, 50)}
                    <div className="open-p"></div>
                  </div>
                  <div className="top-h">
                    <div className="icons" style={{ color: "#65B145" }}>
                      <HiOutlineMailOpen style={{ color: "#65B145" }} />
                    </div>
                    <div className="open-p" style={{ paddingBottom: "3px" }}>
                      {item?.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div id="header-wrap">
          <div className="container-fluid p-0">
            <div className="row row-header-headache">
              <div className="col row-header-headache">
                <nav className="ht-navigation">
                  <a className="navbar-brand logo m-0">
                    <img
                      className="img-fluid"
                      src="../Images/headerlogo.png"
                      alt="Logo Img"
                      style={{ width: "160px", height: "50px" }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        d="M100,0H0v100C0,44.77,44.77,0,100,0Z"
                        fill="none"
                      ></path>
                    </svg>
                  </a>

                  <div className="ht-mobile-menu-bg"></div>

                  {ContactDetails?.map((item) => {
                    return (
                      <div className="navigation-top">
                        <div className="main-nav">
                          <ul id="menu-main-menu" className="menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-10 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  HOME
                                </a>
                              </p>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/about-s"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  ABOUT
                                </a>
                              </p>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/service"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  SERVICES
                                </a>
                              </p>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/gallery"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  GALLERY
                                </a>
                              </p>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/allblog"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  BLOG
                                </a>
                              </p>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/faq"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  FAQ
                                </a>
                              </p>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="headingg-text"
                              >
                                <a
                                  href="/contact"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  CONTACT
                                </a>
                              </p>
                            </li>
                          </ul>
                          <span className="closepanel">
                            <i className="bi bi-x"></i>
                          </span>
                        </div>
                        {/* <button id="menu-toggle" className="nav-menu-toggle ht-toggler" type="button"><i className="bi bi-list"></i></button> */}

                        <div className="right-nav align-items-center d-flex justify-content-end">
                          <div className="header-number">
                            <i className="flaticon-emergency-call">
                              <img
                                src="../Images/emergency-call .png"
                                alt=""
                                style={{ width: "35px", height: "35px" }}
                              />
                            </i>
                            <div>
                              <span>
                                <strong className="phone-no">
                                  {item?.phone}
                                </strong>
                              </span>
                              <span>
                                <strong>{item?.altphone}</strong>
                              </span>
                            </div>
                          </div>

                          <a href="#" className="ht-nav-toggle">
                            <img
                              className="img-fluid"
                              src="https://doctean.themeht.com/wp-content/themes/doctean/assets/images/grid-icon.svg"
                              alt="Logo Img"
                            />
                          </a>
                        </div>
                      </div>
                    );
                  })}

                  <div className="header-right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        d="M100,0H0v100C0,44.77,44.77,0,100,0Z"
                        fill="none"
                      ></path>
                    </svg>
                    <p className="para-button">
                      <div style={{ marginTop: "32px" }}>
                        <Button
                          className="callback-button"
                          onClick={() => setIsFormVisible(!isFormVisible)}
                        >
                          Request a Callback
                        </Button>

                        {/* {isFormVisible === true && (
                <div className='form-container' style={{position:"fixed", marginTop:"34px"}}>
                  <form onSubmit={handleSubmit} className='f-form'>
                    <h3 style={{ color: "#196B9C" }}>Request a Callback &nbsp;&nbsp;&nbsp;&nbsp;x</h3>
                    <div className='form-section'>
                      <div className='form-group'>

                        <input
                         onChange={(e) => setName(e.target.value)}
                         value={Name}
                         type="text" id="name" name="name" placeholder='Your Name' className='selection' />
                      </div>

                      <div className='form-group'>
                        <input
                         value={Email}
                         onChange={(e) => setEmail(e.target.value)} 
                        type="email" id="email" name="email" placeholder='Email Address' className='selection' />
                      </div>

                      <div className='form-group'>
                        <input 
                        value={Phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="tel" id="phone" name="phone" placeholder='Phone Number' className='selection' />
                      </div>

                      <div className='form-group'>
                        <select 
                         value={Service}
                         onChange={(e) => setService(e.target.value)}
                        id="option" name="option" className='selection'>
                          <option value="option1">Select Service</option>

                          {Department?.map((item) => {
                         return (
                          <option value="option2">{item?.departmentName}</option>
                          );
                        })}

                        </select>
                      </div>
                    </div>

                    <div className='form-group mt-2 mb-2'>
                      <textarea
                       value={Message}
                       onChange={(e) => setMessage(e.target.value)}
                       id="message" name="message" rows="4" cols="50" placeholder='Your Message' className='selection'></textarea>
                    </div>
                    <button 
                     onClick={postContactEnquiryForm}
                    type='submit' style={{ backgroundColor: "#196B9C" }} className='btn-callback'>Submit</button>
                  </form>
                </div>
              )} */}

                        {isFormVisible && (
                          <div
                            className="form-container"
                            style={{ position: "fixed", marginTop: "34px" }}
                          >
                            <div className="f-form">
                              <h3 style={{ color: "#196B9C" }}>
                                Request a Callback
                                <span
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => setIsFormVisible(false)}
                                >
                                  &nbsp;&nbsp;&nbsp;x
                                </span>
                              </h3>
                              <div className="form-section">
                                <div className="form-group">
                                  <input
                                    onChange={(e) => setName(e.target.value)}
                                    value={Name}
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Your Name"
                                    className="selection"
                                  />
                                </div>

                                <div className="form-group">
                                  <input
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address"
                                    className="selection"
                                  />
                                </div>

                                <div className="form-group">
                                  <input
                                    value={Phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder="Phone Number"
                                    className="selection"
                                  />
                                </div>

                                <div className="form-group">
                                  <select
                                    value={Service}
                                    onChange={(e) => setService(e.target.value)}
                                    id="option"
                                    name="option"
                                    className="selection"
                                  >
                                    <option value="option1">
                                      Select Service
                                    </option>

                                    {Department?.map((item) => {
                                      return (
                                        <option
                                          value="option2"
                                          key={item.departmentName}
                                        >
                                          {item?.departmentName}
                                        </option>
                                      );
                                    })}
                                    <option value="Others">Others</option>
                                  </select>
                                </div>
                              </div>

                              <div className="form-group mt-2 mb-2">
                                <textarea
                                  value={Message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  id="message"
                                  name="message"
                                  rows="4"
                                  cols="50"
                                  placeholder="Your Message"
                                  className="selection"
                                ></textarea>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#65b145",
                                  color: "white",
                                }}
                                className="btn-callback"
                                onClick={() => {
                                  postContactEnquiryForm();
                                }}
                              >
                                Submit
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="10"
                        height="19"
                        viewBox="0 0 19 19"
                        xmlSpace="preserve"
                      >
                        <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                        <line x1="1.2" y1="1" x2="18" y2="1"></line>
                        <line x1="18" y1="17.8" x2="18" y2="1"></line>
                      </svg>
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <path
                        d="M100,0H0v100C0,44.77,44.77,0,100,0Z"
                        fill="none"
                      ></path>
                    </svg>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <img src="/Images/Logo.PNG" alt="" height={80} width={180} style={{ backgroundColor: "#F1FAFF" }} />
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleOffcanvasShow} />
          <Navbar.Collapse id="navbarScroll" className="d-none d-lg-block">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
              <Nav.Link href="#action1" className='nav-content-color'>HOME</Nav.Link>
              <Nav.Link href="#action2" className='nav-content-color'>ABOUT US</Nav.Link>
              <Nav.Link href="#action3" className='nav-content-color'>SERVICES</Nav.Link>
              <Nav.Link href="#action4" className='nav-content-color'>GALLERY</Nav.Link>
              <Nav.Link href="#action5" className='nav-content-color'>CONTACT US</Nav.Link>
            </Nav>
            <div className="d-flex gap-2" style={{ background: "white" }}>
              <div className='phone-box'>
                <div>
                  <p className="phone-icon fs-2" style={{ color: "#65B145" }}><TbPhoneCall /></p>
                </div>
                <div>
                  <p className='frist-number'>+91 8792440853</p>
                  <p className='second-number'>+91 8970166777</p>
                </div>
              </div>
              <p className="dot-icon fs-1"><PiDotsNineThin /></p>
            </div>
            <div>
              <button className='callback-button' onClick={handleButtonClick}>Request a Callback</button>
              {isFormVisible && (
                <div className='form-container'>
                  <form onSubmit={handleSubmit} className='f-form'>
                    <h3 style={{ color: "#196B9C" }}>Request a Callback</h3>
                    <div className='form-section'>
                      <div className='form-group'>
                        <input type="text" id="name" name="name" placeholder='Your Name' className='selection' />
                      </div>
                      <div className='form-group'>
                        <input type="email" id="email" name="email" placeholder='Email Address' className='selection' />
                      </div>
                      <div className='form-group'>
                        <input type="tel" id="phone" name="phone" placeholder='Phone Number' className='selection' />
                      </div>
                      <div className='form-group'>
                        <select id="option" name="option" className='selection'>
                          <option value="option1">Select Service</option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option>
                        </select>
                      </div>
                    </div>
                    <div className='form-group mt-2 mb-2'>
                      <textarea id="message" name="message" rows="4" cols="50" placeholder='Your Message' className='selection'></textarea>
                    </div>
                    <button type='submit' style={{ backgroundColor: "#196B9C" }} className='btn-callback'>Submit</button>
                  </form>
                </div>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      {/* How you know */}

      {/* <div className='mobile-res'>
        <Callback/>
      </div> */}
    </div>
  );
};

export default Header;
