import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import parse from "html-react-parser";
import axios from "axios";
function Testimonial() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        "https://lehaim.in/api/testimonials/gettestimonials"
      );
      // console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.error(
        "Error fetching testimonials",
        error.response?.data?.error || "An error occurred"
      );
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const activeTestimonials = data.filter((item) => item.status === "Inactive");
  // console.log(activeTestimonials, "isActive>>>..");
  return (
    <>
      {activeTestimonials.length > 0 ? (
        <>
          <div className="ourteam-title">
            <div
              className="our-team"
              style={{ color: "#196b9c", padding: "0px" }}
            >
              Our Testimonials
            </div>
          </div>
          <div className="lfde">
            <div className="conatiner" style={{ position: "relative" }}>
              <div className="ht-shape-btns">
                <svg
                  width="111"
                  height="111"
                  viewBox="0 0 111 111"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                    fill="none"
                  />
                </svg>
              </div>
              <div className="testimonial-quote-home">
                <di>
                  <BiSolidQuoteAltLeft />
                </di>
              </div>
              <div className="frjjjfs">
                <Slider {...settings}>
                  {activeTestimonials.map((item, id) => {
                    return (
                      <div className="row sohfwwww" key={id}>
                        <div className="col-md-6">
                          <div
                            className="elementor-widget-wrap"
                            style={{
                              backgroundImage: `url(https://lehaim.in/HomeServices/${item?.serviceimg})`,
                              backgroundPosition: "top left",
                              backgroundSize: "cover",
                              height: "500px",
                            }}
                          ></div>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <p className="testi-p">
                              {parse(`<div>${item?.description}</div>`)}
                            </p>
                            <div className="testimonial-caption">
                              <h4
                                style={{
                                  fontSize: "30px",
                                  fontWeight: "700",
                                  color: "#196b9c",
                                }}
                              >
                                {item?.name}
                              </h4>
                              <span
                                style={{
                                  fontSize: "18px",
                                  textTransform: "uppercase",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.specification}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Testimonial;
