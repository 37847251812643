import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Button, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Aos from "aos";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";

import "../Styles/Service.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const Service = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  //Frontend Integration (pushing the data from adminpanel to gallery page)

  //Get(Service Banner)

  const [Banner, setBanner] = useState(null);

  const getServiceBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/servicebanner/getservicebanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  console.log("Banner: ", Banner);

  //view more & view less button

  const blogSectionRef = useRef(null);

  const [visibleItems, setVisibleItems] = useState(8);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleView = () => {
    if (isExpanded) {
      setVisibleItems(8); // Show only 8 items
      blogSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setVisibleItems(ServiceDetails.length); // Show all items
    }
    setIsExpanded(!isExpanded);
  };

  //Get(services)

  const [ServiceDetails, setServiceDetails] = useState([]);
  const getServiceDetais = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/servicedetails/getservicedetails"
      );
      if (res.status === 201) {
        setServiceDetails(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  console.log("ServiceDetailssssssssss", ServiceDetails);

  const [Department, setDepartment] = useState([]);
  // console.log("Department", Department);

  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getServiceBanner();
    getServiceDetais();
    getHomeDepartment();
  }, []);

  const activeDepartment = Department.filter(
    (item) => item.status === "Inactive"
  );
  // console.log(Department, "activeDepartment");

  var settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div ref={blogSectionRef}>
      {Banner?.map((item) => {
        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/ServiceBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>
                <div className="mbl-responsive-breadcrumbs">
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item
                      href="/"
                      style={{ color: "rgb(25, 107, 156)" }}
                    >
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Services</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      {/* <div className='head-back-img' style={{ backgroundImage: "url(../Images/all-department-img.jpg)", width: "100%", height: "150px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                <Container>
                    <h1 data-aos='fade-right' data-aos-duration='3000' className='text-dark pt-5 fw-light back-img-head'>Services</h1>
                </Container>
            </div> */}

      <Container className="mt-5">
        {/* <div className="mb-4">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            className="d-flex gap-1 breadcrumb-hover"
                            color="inherit"
                            href="/home"
                        >
                            <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '3px', color: "rgb(25, 107, 156)" }} />
                            <span style={{color:"rgb(25, 107, 156)"}}>Home</span>
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
                            Services
                        </Typography>
                    </Breadcrumbs>
                </div> */}

        <div>
          <h4
            className="text-center fw-bold mb-3"
            style={{ color: "rgb(25, 107, 156)" }}
          >
            OUR SERVICES
          </h4>
          <div className="row" style={{ justifyContent: "center" }}>
            {activeDepartment?.map((item, i) => {
              return (
                <div className="col-md-3" key={item?._id}>
                  <Card
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    className="col-md-4 mt-3 mb-3 mx-2"
                    style={{ width: "15rem" }}
                    onClick={() =>
                      navigate("/nephrologistcare", {
                        state: { item: item },
                      })
                    }
                  >
                    <Card.Img
                      variant="top"
                      className="service-image"
                      // src="../Images/service-img-1.jpg"
                      src={`https://lehaim.in/HomeDepartment/${item?.departmentImage}`}
                      alt="service-img"
                    />
                    <Card.Body>
                      <Card.Title>
                        <Link
                          className="fw-semibold"
                          style={{
                            textDecoration: "none",
                            color: "rgb(25, 107, 156)",
                            fontSize: "18px",
                          }}
                          // to="/nephrologistcare",state{item:item}
                          // onClick(()=>navigate('/nephrologistcare', { state: { item: item } }))
                        >
                          {item?.departmentName}
                        </Link>
                      </Card.Title>
                      <Card.Text>
                        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur. */}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>

          {/* {ServiceDetails.length > 4 && (
                        <Button type="button" className='blogbtn' onClick={handleToggleView}>
                            {isExpanded ? 'View Less' : 'View More'}
                        </Button>
                    )} */}
        </div>
      </Container>
    </div>
  );
};

export default Service;
