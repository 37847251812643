import React, { useState, useEffect } from "react";
import Aos from "aos";
import "../Styles/faq.css";
// import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import parse from "html-react-parser";

const Faq = () => {
  //Get (Faq Content)
  const [faqs, setfaqs] = useState([
    {
      faqQuestion: "What medical services do you provide?",
      faqAnswer:
        "We offer a comprehensive range of medical services across various departments, including Cardiology, Neurology, Urology, General Surgery, Orthopedics, Gynecology, Pediatrics, Internal Medicine, Oncology, ENT, Dermatology, and additional specialties. Visit our services page for more information.",
    },
    {
      faqQuestion: "Do you accept insurance?",
      faqAnswer:
        "Yes, we accept most major insurance plans. Please contact us to verify your specific plan.",
    },
    {
      faqQuestion: "How can I schedule an appointment?",
      faqAnswer:
        "You can schedule an appointment by calling our office or using our online booking system.",
    },
    {
      faqQuestion: "What should I bring to my appointment?",
      faqAnswer:
        "Please bring a valid ID, insurance card, and any relevant medical records.",
    },
    {
      faqQuestion: "What medical services do you provide?",
      faqAnswer:
        "We offer a comprehensive range of medical services across various departments, including Cardiology, Neurology, Urology, General Surgery, Orthopedics, Gynecology, Pediatrics, Internal Medicine, Oncology, ENT, Dermatology, and additional specialties. Visit our services page for more information.",
    },
    {
      faqQuestion: "Is parking available?",
      faqAnswer: "Yes, we have free parking available for all patients.",
    },
    {
      faqQuestion: "Can I see a specialist without a referral?",
      faqAnswer:
        "In most cases, a referral is required. Please check with your insurance provider for details.",
    },
    {
      faqQuestion: "What is your cancellation policy?",
      faqAnswer:
        "We require 24 hours notice for cancellations to avoid a cancellation fee.",
    },
  ]);

  const [Content, setContent] = useState([]);

  const getFaqContents = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/faqcontents/getfaqcontents"
      );
      if (res.status === 201) {
        setContent(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  console.log("Content: ", Content);

  useEffect(() => {
    getFaqBanner();
    getFaqContents();
  }, []);

  // Initialize with indices 0 and 4 open by default
  const [openIndex, setOpenIndex] = useState([0, 4]);

  const handleToggle = (index) => {
    setOpenIndex((prevOpenIndex) => {
      if (prevOpenIndex.includes(index)) {
        // If the clicked question is already open, remove it
        return prevOpenIndex.filter((i) => i !== index);
      } else {
        // Open the clicked question and close others, but keep default open questions 0 and 4
        return [index];
      }
    });
  };

  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0)
  });

  //Frontend Integration (pushing the data from adminpanel to gallery page)

  //Get (Faq Banner)

  const [Banner, setBanner] = useState(null);

  const getFaqBanner = async () => {
    try {
      let res = await axios.get("https://lehaim.in/api/faqbanner/getfaqbanner");
      if (res.status === 201) {
        setBanner(res.data.data1);

        console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  const [ShowData, setShowData] = useState(false);

  return (
    <div>
      {Banner?.map((item) => {
        return (
          <section
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/FaqBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>

                <div className="mbl-responsive-breadcrumbs">
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}
      {/* 
            <section id='faq1'>
                <div className=''>
                    <h1 className='faq-heading'>Faq</h1>
                    <p className='homebar-faq'>                        
                        Home/Faq
                    </p>
                </div>
            </section> */}

      <section id="faq2">
        <div className="container">
          <div className="popularque">
            <p>POPULAR QUESTION</p>
            <h1>
              Your Ultimate Medical <br /> Query Resource
            </h1>
          </div>
        </div>
      </section>

      <section id="faq3">
        <div
          className="faq-section"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          {Content?.map((faq, index) => {
            const isActive = openIndex === index;

            return (
              <>
                <div
                  key={index}
                  className="faq-column"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <div className="faq-item">
                    <div
                      className={`faq-question ${isActive ? "active" : ""}`}
                      onClick={() => setOpenIndex(isActive ? null : index)}
                    >
                      <span className="question-text">{faq.faqQuestion}</span>
                      <span className="eye-button">
                        {/* {openIndex.includes(index) ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>} */}
                        {isActive ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </span>
                    </div>
                    {isActive && (
                      <div className="faq-answer">
                        {parse(`<div>${faq.faqAnswer}</div>`)}
                        {/* <p>{faq.faqAnswer}</p> */}
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          {/* <div className="faq-column" data-aos="zoom-in" data-aos-duration="1000">
                                <div key={index + 4} className="faq-item">
                                    <div
                                        className={`faq-question ${openIndex.includes(index + 4) ? 'active' : ''}`}
                                        onClick={() => handleToggle(index + 4)}
                                    >
                                        <span className="question-text">{faq.faqQuestion}</span>
                                        <span className="eye-button">
                                            {openIndex.includes(index + 4) ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                        </span>
                                    </div>
                                    {openIndex.includes(index + 4) && (
                                        <div className="faq-answer">
                                            {parse(`<div>${faq.faqAnswer}</div/>`)}

                                        </div>
                                    )}
                                </div>
                            </div> */}
        </div>
      </section>
    </div>
  );
};

export default Faq;
