import React, { useRef, useState } from "react";
import "../Styles/login.css";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";

function Login() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const formdata = new FormData();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validatePassword = (password) => {
    const passwordregex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&]).{8,}$/;
    if (passwordregex.test(password)) {
      return true;
    } else {
      toast.warning("enter valid password");
    }
  };

  const validateEmail = (email) => {
    const emailregex = /\S+@\S+\.\S+/;
    if (emailregex.test(email)) {
      return true;
    } else {
      toast.warning("enter valid email");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      toast.warning("Please enter your email");
      return;
    }
    if (!formData.password) {
      toast.warning("Please enter your password");
      return;
    }

    if (validateEmail(formData.email) && validatePassword(formData.password)) {
      try {
        formdata.set("email", formData.email);
        formdata.set("password", formData.password);
        const config = {
          url: "/login",
          method: "post",
          baseURL: "https://lehaim.in/api/user",
          headers: { "content-type": "application/json" },
          data: formdata,
        };
        const response = await axios(config);

        if (response.status === 200) {
          toast.success("logged in...");
          navigate("/admin_slider");
          sessionStorage.setItem("user", JSON.stringify(response.data));
        } else {
          toast.warning("invalid creadentials");
        }
      } catch (error) {
        toast.error("invalid credentials");
      }
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [emails, setEmails] = useState();
  const sendMail = async () => {
    if (!emails) {
      return alert("Please Enter Your Email");
    }
    try {
      const config = {
        url: "/send-otp",
        baseURL: "https://lehaim.in/api/user",
        method: "post",
        Headers: { "Content-Type": "application/json" },
        data: {
          email: emails,
        },
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          alert(res.data.success);
          handleClose2();
          handleShow3();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  // OTP Verification

  const [Otp, setOtp] = useState("");

  const Otpverification = async () => {
    try {
      const config = {
        url: "/verify-otp",
        baseURL: "https://lehaim.in/api/user",
        method: "post",
        Headers: { "Content-Type": "application/json" },
        data: {
          email: emails,
          otp: Otp,
        },
      };
      await axios(config).then((res) => {
        if (res.status == 200) {
          alert(res.data.success);
          handleShow3();
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // New Password
  const [passwords, setpasswords] = useState();
  const [cpasswords, setcpasswords] = useState();
  const NewPassword = async () => {
    if (passwords !== cpasswords) {
      return alert("New password & Confirm password does not match");
    }
    // alert(Email)
    try {
      const config = {
        url: "/new-password",
        baseURL: "https://lehaim.in/api/user",
        method: "put",
        headers: { "Content-Type": "application/json" },
        data: {
          password: passwords,
          email: emails,
        },
      };

      const response = await axios(config);

      if (response.status === 200) {
        alert("Password update successfully");
        window.location.reload();
      } else {
        alert("Password update failed");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid  myContainer2">
        <div
          className="row  d-flex justify-content-center align-items-center "
          style={{ isolation: "isolate" }}
        >
          <div className="col-md-5 border rounded-0 mt-5 p-5 myCol1 position-relative">
            <div className="row ">
              <h3>Login</h3>
            </div>

            <form>
              <div class="row d-flex justify-content-center">
                <input
                  type="email"
                  name="email"
                  id=""
                  className="myInput1"
                  placeholder="Email Address"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <br />
              </div>
              <div class="row d-flex justify-content-center mt-4">
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  id=""
                  className="myInput1"
                  placeholder="Enter Password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div
                className=""
                style={{
                  position: "absolute",
                  right: "90px",
                  top: "218px",
                  zIndex: "999",
                }}
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <IoMdEyeOff /> : <IoMdEye />}
              </div>
              <div class="row d-flex justify-content-center mt-3">
                <Link to="#" onClick={handleShow2}>
                  {" "}
                  Forgot Password ?
                </Link>
              </div>
              <div class="row d-flex justify-content-center">
                <button
                  type="button"
                  className="button1"
                  onClick={handleSubmit}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <input
            type="email"
            className="myInput1"
            placeholder="Enter your email"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              sendMail();
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      {/* otp */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="myInput1"
            placeholder="Enter your otp"
            value={Otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              Otpverification();
              handleShow4();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* newpassword */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="myInput1"
            placeholder="Enter your new password"
            value={passwords}
            onChange={(e) => setpasswords(e.target.value)}
          />
          <input
            type="text"
            className="myInput1 mt-2"
            placeholder="Confirm passwords"
            value={cpasswords}
            onChange={(e) => setcpasswords(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button variant="primary" onClick={NewPassword}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
