import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "../Styles/serviceshome.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function ServicesHome() {
  const navigate = useNavigate();

  const options = {
    margin: 20,
    responsiveClass: true,
    dots: true,
    nav: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  //Get  Service

  const [Department, setDepartment] = useState([]);
  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHomeDepartment();
  }, []);

  //Active logic(admin panel)

  const isActive = Department.some((item) => item.status === "Inactive");
  // console.log("Department=============>", isActive);

  return (
    <div>
      {isActive ? (
        <>
          <div className="ourteam-title">
            <div className="our-team" style={{ color: "#196b9c" }}>
              Our Services
            </div>
          </div>

          <div className="container">
            <OwlCarousel
              className="owl-theme"
              dots={true}
              loop={false}
              autoPlay={true}
              nav
              margin={8}
              {...options}
            >
              {Department.map((item) => {
                // Only display active departments
                if (item.status === "Inactive") {
                  return (
                    <div
                      key={item._id}
                      onClick={() => {
                        navigate("/nephrologistcare", { state: { item } });
                      }}
                      className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b857cf6"
                      data-id="b857cf6"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-2fdbb4f elementor-widget elementor-widget-feature"
                          data-id="2fdbb4f"
                          data-element_type="widget"
                          data-widget_type="feature.default"
                        >
                          <div className="elementor-widget-container this-service-for-margin">
                            <div
                              className="featured-item style-1 fea-mobile-res-style"
                              style={{ padding: "0px", width: "359px" }}
                            >
                              <img
                                alt=""
                                loading="lazy"
                                src={`https://lehaim.in/HomeDepartment/${item?.departmentImage}`}
                                style={{
                                  width: "359px",
                                  borderRadius: "20px",
                                  height: "260px",
                                }}
                                className="service-image-mobile-res"
                              />
                              <div
                                className="ht-shape-btn ht-btn-shape-mobile-res"
                                style={{
                                  left: "-1px",
                                  right: "auto",
                                  transform: "rotate(-90deg)",
                                }}
                              >
                                <svg
                                  width="111"
                                  height="111"
                                  viewBox="0 0 111 111"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                    fill="none"
                                  ></path>
                                </svg>
                              </div>

                              <div
                                className="featured-service-icon"
                                style={{ left: "-1px" }}
                              >
                                <img
                                  src={`https://lehaim.in/HomeDepartment/${item?.departmentIcon}`}
                                  alt=""
                                  style={{ width: "80px", height: "50px" }}
                                />
                              </div>

                              <div
                                className="ht-shape-btn up-arrow-button-res"
                                style={{
                                  transform: "rotate(90deg)",
                                  bottom: "-236px",
                                  right: "unset",
                                  left: "7.5%",
                                }}
                              >
                                <svg
                                  width="111"
                                  height="111"
                                  viewBox="0 0 111 111"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0C19.33 0 35 15.67 35 35V41C35 60.33 50.67 76 70 76H76C95.33 76 111 91.67 111 111V0H0Z"
                                    fill="none"
                                  ></path>
                                </svg>
                              </div>
                              {/* <Link to={"./service"}> */}
                              {/* <div
                                  className="featured-service-icon featured-icon-service-mobile-res"
                                  style={{ right: "-5px", top: "265px" }}
                                >
                                  <img
                                    src="../Images/up-right-arrow.png"
                                    alt=""
                                    style={{ width: "20px" }}
                                  />
                                </div> */}
                              {/* </Link> */}

                              <div className="featured-desc">
                                <div className="s-para"></div>
                                <div className="service-title">
                                  <h4
                                    style={{
                                      color: "#196b9c",
                                      fontSize: "25px",
                                    }}
                                    className="c-head"
                                  >
                                    {item?.departmentName}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </OwlCarousel>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ServicesHome;
