import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faEnvelope,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";

export const NephrologistCare = () => {
  const [ServiceId, setServiceId] = useState();
  const location = useLocation();
  // let item = location.state?.item;

  let initialItem = location.state?.item || null;

  const [item, setItem] = useState(initialItem);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [item]);

  const handleCarouselItemClick = (clickedItem) => {
    setItem(clickedItem);
  };

  // console.log("item1111111111", item);
  // const [serviceDetailsObj, setserviceDetailsObj] = useState({});

  // console.log("serviceDetailsObj", serviceDetailsObj);

  // console.log("ServiceId", ServiceId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const getServicesByid = async (ServiceId) => {
  //   try {
  //     let res = await axios.get(
  //       "https://lehaim.in/api/servicedetails/getservicedetailsById/" +
  //         ServiceId
  //     );
  //     if (res.status === 200) {
  //       setserviceDetailsObj(res.data.getServiceDetails);
  //       window.scroll(0, 0);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   if (ServiceId) {
  //     getServicesByid(ServiceId);
  //   }
  // }, [ServiceId]);

  // useEffect(() => {
  //   if (item?.selectService?._id) {
  //     getServicesByid(item?.selectService?._id);
  //   }
  // }, []);

  // const getServicesByitem = async () => {
  //   try {
  //     let res = await axios.get(
  //       "https://lehaim.in/api/servicedetails/getservicedetailsById/" +
  //         item?._id
  //     );
  //     if (res.status === 200) {
  //       setserviceDetailsObj(res.data.getServiceDetails);
  //       window.scroll(0, 0);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (item) {
  //     getServicesByitem();
  //   }
  // }, [item]);

  const [Department, setDepartment] = useState([]);
  const getHomeDepartment = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/homedepartment/gethomedepartment"
      );
      if (res.status === 201) {
        setDepartment(res.data.status);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  //view more and view less button

  const blogSectionRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleView = () => {
    if (isExpanded) {
      setVisibleItems(4);
      blogSectionRef.current?.scrollIntoView({ behavior: "smooth" }); //for scrolling
    } else {
      setVisibleItems(ServiceDetails.length);
      blogSectionRef.current?.scrollIntoView({ behavior: "smooth" }); //for scrolling
    }
    setIsExpanded(!isExpanded);
  };

  const [ServiceDetails, setServiceDetails] = useState([]);
  const getServiceDetais = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/servicedetails/getservicedetails"
      );
      if (res.status === 201) {
        setServiceDetails(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // console.log("ServiceDetails66464646: ", ServiceDetails);

  useEffect(() => {
    getHomeDepartment();
    getServiceDetais();
  }, []);

  let [isFormVisible, setIsFormVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [ContactDetails, setContactDetails] = useState(null);

  const getContactDetails = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/contactdetails/getcontactdetails"
      );
      if (res.status === 201) {
        setContactDetails(res.data.data1);
        // console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };
  // console.log("ContactDetails: ", ContactDetails);

  const [Banner, setBanner] = useState(null);

  const getServiceBanner = async () => {
    try {
      let res = await axios.get(
        "https://lehaim.in/api/servicebanner/getservicebanner"
      );
      if (res.status === 201) {
        setBanner(res.data.data1);
        // console.log(res.data.data1);
      }
    } catch (error) {
      console.log(error, "data some data");
    }
  };

  // console.log("Banner: ", Banner);

  useEffect(() => {
    getContactDetails();
    getServiceBanner();
    window.scrollTo(0, 0);
  }, []);

  const [Desc, setDesc] = useState();

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Service, setService] = useState("");
  const [Message, setMessage] = useState("");

  const validatePhoneNumber = (number) => {
    const re = /^[6-9]\d{9}$/;
    return re.test(number);
  };

  const postContactEnquiryForm = async () => {
    if (!Name) {
      return alert("Name required !");
    }
    if (!validatePhoneNumber(Phone)) {
      return alert("Please enter a valid phone number (10 digits)");
    }
    if (!Email) {
      return alert("Email required !");
    }
    if (!Service) {
      return alert("Service required !");
    }
    if (!Message) {
      return alert("Message required !");
    }
    try {
      const config = {
        url: "/createcontactenquiryform",
        method: "post",
        baseURL: "https://lehaim.in/api/contactenquiryform",
        headers: { "content-type": "application/json" },
        data: {
          name: Name,
          phone: Phone,
          email: Email,
          message: Message,
          service: Service,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("We will call you shortly...");
        setName("");
        setPhone("");
        setEmail("");
        setService("");
        setMessage("");
        setIsFormVisible(false);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const activeDepartment = Department.filter(
    (item) => item.status === "Inactive"
  );
  // console.log(activeDepartment, "activeDepartment");

  return (
    <div>
      {Banner?.map((item) => {
        return (
          <section
            key={item?._id}
            id="about"
            style={{
              backgroundImage: `url(https://lehaim.in/ServiceBanner/${item?.bannerImg})`,
            }}
          >
            <div className="container ">
              <div className="breadcrumbt">
                <div>
                  <h1 className="abtus-heading">{item?.bannerText}</h1>
                </div>

                <div className="mbl-responsive-breadcrumbs">
                  <Breadcrumb>
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        color: "rgb(25, 107, 156)",
                        padding: "5px",
                      }}
                    />
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Services</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      <Container className="mt-5" style={{ position: "relative" }}>
        <Container>
          <div className="row mb-2 ">
            <div className="col-md-6 col-lg-4 mb-2 ">
              <div className="dfdsf">
                <img
                  className="img-fluid "
                  style={{
                    width: "100%",
                    height: "280px",
                    borderRadius: "10px",
                  }}
                  src={`https://lehaim.in/HomeDepartment/${item?.departmentImage}`}
                  alt="department-img"
                />
              </div>
            </div>
            <div className="col-md-6  col-lg-4 mb-2 ">
              <h3
                className="fw-bold mb-2"
                style={{ color: "rgb(25, 107, 156)" }}
              >
                {item.departmentName}
              </h3>
              <p style={{ textAlign: "justify" }}>
                {parse(`<div>${item?.discription}</div>`)}
              </p>
            </div>

            <div className="col-md-12 col-lg-4">
              <div className="grger">
                <h5
                  className="fw-bold mb-2 ms-4"
                  style={{ color: "rgb(25, 107, 156)" }}
                >
                  Other Services
                </h5>

                <div>
                  <ul
                    className="other-services"
                    style={{ listStyleType: "square", color: "black" }}
                  >
                    {activeDepartment?.map((val) => {
                      return (
                        <li
                          className="other-service-content"
                          style={{ listStyle: "none" }}
                        >
                          <span
                            onClick={() => {
                              handleCarouselItemClick(val);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faAnglesRight}
                              style={{ marginRight: "10px" }}
                            />
                            {val?.departmentName}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {ContactDetails?.map((item) => {
          return (
            <div className="ms-4 mb-4">
              <h5
                className="fw-bold mb-2 fs-3"
                style={{ color: "rgb(25, 107, 156)" }}
              >
                Contact Details
              </h5>
              <div className="d-flex justify-content-between align-item-center">
                <div>
                  <h5>E-mail</h5>
                  <a
                    className="text-dark"
                    href="/contact"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} beatFade /> {item?.email}
                  </a>{" "}
                  <br />
                </div>

                <div>
                  <h5>Phone No.</h5>
                  <a
                    className="text-dark"
                    href="/contact"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faPhoneVolume}
                      beatFade
                      style={{ paddingLeft: "10px" }}
                    />{" "}
                    {item?.phone}
                  </a>{" "}
                  <br />
                </div>

                <div>
                  <h5>Contact Us</h5>

                  <Button
                    className="callback-button"
                    onClick={() => setIsFormVisible(!isFormVisible)}
                  >
                    Request a Callback
                  </Button>

                  {/* {isFormVisible === true && (
                <div
                  className="form-container"
                  style={{ position: "fixed", marginTop: "34px" }}
                >
                  <form onSubmit={handleSubmit} className="f-form">
                    <h3 style={{ color: "#196B9C" }}>Request a Callback</h3>
                    <div className="form-section">
                      <div className="form-group">
                        <input
                        onChange={(e) => setName(e.target.value)}
                        value={Name}
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          className="selection"
                        />
                      </div>
                      <div className="form-group">
                        <input
                         value={Email}
                         onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          className="selection"
                        />
                      </div>
                      <div className="form-group">
                        <input
                        value={Phone}
                        onChange={(e) => setPhone(e.target.value)}
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          className="selection"
                        />
                      </div>
                      <div className="form-group">
                        <select
                         value={Service}
                         onChange={(e) => setService(e.target.value)}
                         id="option" name="option" className="selection">
                          <option value="option1">Select Service</option>

                          {Department?.map((item) => {
                           return (
                          <option value="option2">{item?.departmentName}</option>
                            );
                          })}

                        </select>
                      </div>
                    </div>
                    <div className="form-group mt-2 mb-2">
                      <textarea
                      value={Message}
                      onChange={(e) => setMessage(e.target.value)}
                        id="message"
                        name="message"
                        rows="4"
                        cols="50"
                        placeholder="Your Message"
                        className="selection"
                      ></textarea>
                    </div>
                    <button
                      onClick={postContactEnquiryForm}
                      type="submit"
                      style={{ backgroundColor: "#196B9C" }}
                      className="btn-callback"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )} */}

                  {isFormVisible && (
                    <div
                      className="form-container"
                      style={{ position: "fixed", marginTop: "34px" }}
                    >
                      <form onSubmit={handleSubmit} className="f-form">
                        <h3 style={{ color: "#196B9C" }}>
                          Request a Callback
                          <span
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => setIsFormVisible(false)}
                          >
                            &nbsp;&nbsp;&nbsp;x
                          </span>
                        </h3>
                        <div className="form-section">
                          <div className="form-group">
                            <input
                              onChange={(e) => setName(e.target.value)}
                              value={Name}
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Your Name"
                              className="selection"
                            />
                          </div>

                          <div className="form-group">
                            <input
                              value={Email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Email Address"
                              className="selection"
                            />
                          </div>

                          <div className="form-group">
                            <input
                              value={Phone}
                              onChange={(e) => setPhone(e.target.value)}
                              type="tel"
                              id="phone"
                              name="phone"
                              placeholder="Phone Number"
                              className="selection"
                            />
                          </div>

                          <div className="form-group">
                            <select
                              value={Service}
                              onChange={(e) => setService(e.target.value)}
                              id="option"
                              name="option"
                              className="selection"
                            >
                              <option value="option1">Select Service</option>

                              {Department?.map((item) => {
                                return (
                                  <option
                                    value="option2"
                                    key={item.departmentName}
                                  >
                                    {item?.departmentName}
                                  </option>
                                );
                              })}
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group mt-2 mb-2">
                          <textarea
                            value={Message}
                            onChange={(e) => setMessage(e.target.value)}
                            id="message"
                            name="message"
                            rows="4"
                            cols="50"
                            placeholder="Your Message"
                            className="selection"
                          ></textarea>
                        </div>
                        <button
                          onClick={postContactEnquiryForm}
                          type="submit"
                          style={{ backgroundColor: "#65b145", color: "white" }}
                          className="btn-callback"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {/* <div className="container">
          <h3
            className="fw-bold mb-1 text-center"
            style={{ color: "rgb(25, 107, 156)" }}
          >
            {" "}
            Other Services{" "}
          </h3>

          <Button
            variant="primary"
            className="blogbtn"
            onClick={handleToggleView}
            style={{ marginBottom: "20px" }}
          >
            {isExpanded ? "View Less" : "View More"}
          </Button>

          <div className="row mb-4" ref={blogSectionRef}>
            {ServiceDetails?.slice(0, visibleItems)?.map((item, i) => {
              return (
                <div
                  className="col-md-3 mt-4"
                  onClick={() => {
                    setServiceId(item?.selectService?._id);
                  }}
                >
                  <Card className="m-auto" style={{ width: "15rem" }}>
                    <Card.Img variant="top" />

                    <img
                      style={{ width: "100%", height: "180px" }}
                      src={`https://lehaim.in/HomeDepartment/${item?.selectService?.departmentImage}`}
                      alt="Doctor-img"
                    />

                    <Card.Body>
                      <Card.Title>
                        <div
                          className="fw-semibold other-service-deptname"
                          style={{
                            textDecoration: "none",
                            color: "rgb(25, 107, 156)",
                          }}
                        >
                          {item?.selectService?.departmentName}
                        </div>
                      </Card.Title>
                      <Card.Text>
                        {parse(
                          `<span>${item?.description?.slice(
                            0,
                            100
                          )}.....</span>`
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </div> */}
      </Container>
    </div>
  );
};
